(function(w, d) {
    if (typeof NodeList.prototype.forEach !== "function") { NodeList.prototype.forEach = Array.prototype.forEach; } /** IE11 polyfill */
    var discovery = {
        init: function () {
            w.discovery_config.forEach(function(o) {
                var ads = [],
                    advertising = (o.ad_dfp_interval && o.ad_dfp_slot) ? true : false,
                    cards = [],
                    searchJSON = __tnt.scrubUrl(o.search_url),
                    target = document.querySelector(o.trigger);
                
                // Supplemental discovery targets
                if (o.fetch_method_alt != 'disabled') {
                    var alt_trigger = document.getElementById('trigger-next-'+ o.block_id +'-supplement'),
                        alt_drop = document.getElementById('content-discovery-'+ o.block_id +'-supplement');
                }
                
                // Set up for supplemental discovery
                if (o.supplemental_discovery) {
                    var sup_drop = d.querySelector(o.drop_zone);
                    const sup_observer = new MutationObserver(() => {
                        // Update our searchJSON with provided fetch
                        if (sup_drop.dataset.fetch) searchJSON = d.querySelector(o.drop_zone).dataset.fetch;
                    });
                    sup_observer.observe(sup_drop, {
                        attributeFilter: ['data-fetch'],
                    });
                    
                    // Swap in provided fetch URL
                    if (sup_drop.dataset.fetch) searchJSON = d.querySelector(o.drop_zone).dataset.fetch;
                }
                
                /** Section select (optional fetch method) */
                if (o.fetch_method == 'select') {
                    var baseSet = d.querySelector(o.drop_zone).nextElementSibling,
                        storeKey = 'select-' + o.block_id,
                        storedChoice = JSON.parse(localStorage.getItem(storeKey));
                    
                    // Manage stored options
                    if (storedChoice) {
                        // Set fetch URL to stored value, and ensure limit matches config
                        searchJSON = storedChoice.fetch.replace(/(&l=)[^&]*/g, '&l=' + o.max);
                        
                        if (o.fetch_method_alt == 'link') {
                            // Get link element and set href to match. Start at max from block and use default search limit
                            var linkHref = searchJSON.replace(/(&f=json&altf=full)/g, '').replace(/(&l=)[^&]*/g, '') + '&o=' + o.max;
                            alt_trigger.setAttribute('href', linkHref);
                        } else if (o.fetch_method_alt != 'disabled') {
                            // Place our searchJSON on the supplemental dropzone for access
                            alt_drop.dataset.fetch = searchJSON + '&o=' + o.max;
                        }
                        
                        /* Run through sections list for grouping */
                        o.sections.forEach(function(option, i) {
                            // Compare All list to Followed for initial grouping
                            if (storedChoice.choice.includes(option.value)) {
                                // Swap in followed class on match
                                o.sections[i].class = 'followed';
                            }
                        });
                        
                        // Run fetch assets to get our choice of assets
                        fetchAssets();
                    } else {
                        // Nothing stored. Show base set
                        baseSet.classList.remove('hidden');
                        
                        if (o.fetch_method_alt != 'disabled') {
                            // Show supplemental discovery target for base set if needed
                            if(o.base_count >= o.base_max) {
                                alt_trigger.classList.remove('hidden');
                            }
                        }
                    }
                    
                    /* Select controls init */
                    var control = new TomSelect(target, {
                            maxItems: 1, // Number of choices allowed
                            hideSelected: false,
                            duplicates: false,
                            closeAfterSelect: false, // Running .close() instead
                            hidePlaceholder: true,
                            plugins: [
                                'clear_button',
                            ],
                            options: o.sections, // Pass in our sections list
                            optgroups: [
                                {value: 'followed', label: o.label_followed},
                                {value: 'all', label: o.label_all}
                            ],
                            lockOptgroupOrder: true, // Maintain optgroups order as specified above
                            optgroupField: 'class',
                            labelField: 'text',
                            render: {
                                optgroup_header: function(data, escape) {
                                    return '<div class="optgroup-header"><strong>' + escape(data.label) + '<strong></div>';
                                }
                            }
                        });
                    
                    // Set inner controls to match select width
                    var selectElem = document.getElementById('trigger-next-'+ o.block_id +'-ts-control').closest('.ts-control');
                    selectElem.nextElementSibling.style.width = selectElem.offsetWidth + 'px';
                    
                    // Set the select to match the stored choice
                    if (storedChoice) control.setValue(storedChoice.choice, false);
                    
                    // Handle discovery select changes
                    target.onchange = function(e) {
                        // Get selected options
                        var inputVal = control.getValue();
                        
                        // Update select grouping
                        updateSelect(inputVal);
                        
                        // Ensure supplemental discovery trigger is available
                        if (o.fetch_method_alt != 'disabled' && o.fetch_method_alt != 'link') {
                            alt_trigger.classList.remove('hidden');
                        }
                        
                        // If choices cleared
                        if (inputVal == null || inputVal == '') {
                            
                            // Display base set, and hide dropzone
                            d.querySelector(o.drop_zone).classList.add('hidden');
                            baseSet.classList.remove('hidden');
                            
                            // Clear stored choice
                            try {
                                localStorage.removeItem(storeKey);
                            } catch(e) {}
                            
                            // Clear supplemental
                            if (o.fetch_method_alt == 'link'){
                                // Get link elem and set href to match
                                var linkHref = __tnt.scrubUrl(o.search_url.replace(/(&f=json&altf=full)/g, '').replace(/(&l=)[^&]*/g, '') + '&o=' + o.max);
                                alt_trigger.setAttribute('href', linkHref);
                            } else if (o.fetch_method_alt != 'disabled') {
                                // Clear supplemental discovery area 
                                alt_drop.innerHTML = '';
                                
                                // Remove section fetch attribute
                                alt_drop.dataset.fetch = __tnt.scrubUrl(o.search_url + '&o=' + o.max);
                            }
                            
                            // No need to fetch
                            return;
                        }
                        
                        // Change block search bl param to block to broaden search and provide settings
                        searchJSON = searchJSON.replace(/(bl=)/g, 'block=');
                        
                        // Update fetch for selected section searh
                        if (searchJSON.includes('&c[]=')) {
                            // Find c value and replace 
                            searchJSON = searchJSON.replace(/(&c\[\]=)[^&]*/g, '&c[]=' + inputVal);
                        } else {
                            // Include c value
                            searchJSON = searchJSON + '&c[]=' + inputVal;
                        }
                        
                        // Store choice and fetch url for later use
                        var storedData = { choice: inputVal, fetch: searchJSON};
                        localStorage.setItem(storeKey, JSON.stringify(storedData));
                        
                        // Provide supplemental discovey with active choices
                        if (o.fetch_method_alt == 'link') {
                            // Get link elem and set href to match
                            var linkHref = searchJSON.replace(/(&f=json&altf=full)/g, '').replace(/(&l=)[^&]*/g, '') + '&o=' + o.max;
                            alt_trigger.setAttribute('href', linkHref);
                        } else if (o.fetch_method_alt != 'disabled') {
                            // Place our searchJSON on the supplemental dropzone for access
                            alt_drop.dataset.fetch = searchJSON + '&o=' + o.max;
                        }
                        
                        // Fetch assets
                        fetchAssets();
                    } // End onchange event handler
                }
                
                /** Infinity (optional fetch method) */
                else if (o.fetch_method == "infinity") {
                    // Observer callback
                    var callback = function(entries, io) { 
                        entries.forEach(function(entry) {
                            if (entry.isIntersecting === true) {
                                fetchAssets();
                            }
                        });
                    };
                    
                    // Initialize observer
                    var config = { rootMargin: "0px 0px 0px" },
                        io = new IntersectionObserver(callback, config);
                    io.observe(target);
                }
                
                /** Load more (optional fetch method) */
                else if (o.fetch_method == "more") {
                    target.addEventListener("click", function(e){
                        spinnerControl();
                        fetchAssets(spinnerControl);
                    });
                }
                
                // Toggle visibility
                function toggle(el) {
                    if (typeof el !== "undefined") {
                        var display = (window.getComputedStyle ? getComputedStyle(el, null) : el.currentStyle).display;
                        if (display == "none") {
                            el.style.display = "";
                        } else {
                            el.style.display = "none";
                        }
                    }
                }
                
                // Spinner display
                function spinnerControl() {
                    var loaderText = document.querySelectorAll(o.trigger + " .more-text");
                    for (i = 0; i < loaderText.length; ++i) {
                        toggle( loaderText[i] );
                    }
                }
                
                // Fetch assets
                function fetchAssets(callback) {
                    if (o.supplemental_discovery) {
                        // Ensure limit runs max
                        searchJSON = searchJSON.replace(/(&l=)[^&]*/g, '&l=' + o.max);
                    }
                    
                    // Run fetch
                    fetch(searchJSON)
                        .then(function(response) {
                            if (response.status !== 200) {
                                __tnt.log("Error. Status Code: " + response.status);
                                hideTrigger();
                                return;
                            }
                        // Examine response
                        response.json()
                            .then(function(data) {
                                // Prepare for next run
                                if (data.next !== 0) {
                                    
                                    if (o.fetch_method == 'select') {
                                        if (o.fetch_method_alt != 'disabled') {
                                            // Show supplemental discovery target
                                            alt_trigger.classList.remove('hidden');
                                        }
                                    } else {
                                        if (searchJSON.includes('&o=')) {
                                            // Set up for next set
                                            searchJSON = searchJSON.replace(/&o=[0-9]*/g, "&o=" + data.next);
                                        } else {
                                            // Include offset value
                                            searchJSON = searchJSON + '&o=' + data.next;
                                        }
                                    }
                                } else {
                                    // End of results
                                    hideTrigger();
                                }
                                
                                // Populate assets
                                populateCards(data.assets);
                                
                                // Callback
                                if (typeof callback === "function") {
                                    callback();
                                }
                        });
                    })
                    .catch(function(err) {
                        __tnt.log("Fetch Error: ", err);
                        hideTrigger();
                    });
                }
                
                // Populate cards with fetched assets
                function populateCards(assets) {
                    if (o.fetch_method == "select") {
                        if (o.fetch_method_alt != 'disabled' && o.fetch_method_alt != 'link') {
                            // Clear supplemental discovery area
                            alt_drop.innerHTML = '';
                        }
                        
                        if (assets.length <= 0) {
                            // Show notice
                            notice('There are currently no results for this selection.');
                            return;
                        }
                    }
                    
                    if (assets) {
                        fillTemplate(assets);   // Inserts asset data into card templates
                        renderCards();          // Drops card on to the page
                        pageManagement();       // Hanldes offpage display
                    } else {
                        __tnt.log("Content discovery error: Unexpected JSON response.");
                        hideTrigger();
                    }
                }
                
                // Notice
                function notice(text) {
                    // Insert notice
                    d.querySelector(o.drop_zone).innerHTML = '<p class="alert alert-info">'+ text +'</p>';
                    // Show dropzone
                    d.querySelector(o.drop_zone).classList.remove("hidden")
                    // Hide base set
                    baseSet.classList.add("hidden");
                }
                
                // Insert cards
                function renderCards() {
                    var df = d.createDocumentFragment(),
                        page = d.createElement("div"),
                        content = d.createElement("div");
                    
                    page.classList.add("page");
                    content.classList.add("content");
                    if (o.col_count) {
                        content.setAttribute("data-grid-cols", o.col_count); /** For IE11 CSS grid polyfill */
                    }
                    
                    df.appendChild(page);
                    df.querySelector(".page").appendChild(content);
                    
                    cards.forEach(function(card) {
                        df.querySelector(".content").appendChild(card);
                    });
                    
                    if (o.fetch_method == "select") {
                        // Clear dropzone for new assets
                        d.querySelector(o.drop_zone).innerHTML = "";
                        
                        // Show/hide dropzone and base set
                        d.querySelector(o.drop_zone).classList.remove("hidden")
                        baseSet.classList.add("hidden");
                    }
                    
                    d.querySelector(o.drop_zone).appendChild(df);
                    alterCards();
                }
                
                // Adjust afer render
                function alterCards() {

                    // Stylize hammer
                    if (o.show_hammer) {
                        $(".tnt-hammer").bigtext({maxfontsize: 200});
                    }
                    
                    // Render ads
                    if (advertising) {
                        for (var i = 0; i < ads.length; i++) {
                            if (!ads[i].rendered) {
                                __tnt.ads.dfp.func.fetchAd(ads[i].id);
                                ads[i].rendered = true;
                            }
                        }
                    }
                }
                
                // Manage offscreen pages and return to previous pages
                function pageManagement() {
                    if (o.fetch_method == "select") return;
                    
                    var returnCallback = function(entries, returnio) {
                        entries.forEach(function(entry) {
                            if (entry.isIntersecting === true) {
                                // returning to viewport
                                entry.target.firstElementChild.classList.remove("hidden");
                            } else {
                                // out of viewport
                                entry.target.firstElementChild.classList.add("hidden");
                            }
                        });
                    };
                    
                    var container = d.querySelector(o.drop_zone),
                        arrival = container.lastElementChild, // added to the bottom of viewport
                        active = arrival.previousElementSibling; // on the edge of the viewport
                    
                    if (active) {
                        var prev = active.previousElementSibling; // out of viewport to be hidden/removed
                        if (prev) {
                            var height = __tnt.outerHeight(prev, true);
                            prev.style.height = (height + "px");
                            prev.firstElementChild.classList.add("hidden");
                            prev.classList.add("return-trigger");
                            // Observe removed page for later interaction
                            var config = {
                                rootMargin: "100px 0px 100px"
                            };
                            var returnio = new IntersectionObserver(returnCallback, config);
                            returnio.observe(prev);
                        }
                    }
                }
                
                // Fill template
                function fillTemplate(assets){
                    if (assets.length <= 0) return;
                    
                    const cardWidth = d.querySelector("#block-" + o.block_id + " .card").offsetWidth;
                    var adInt = 0;
                    assets.forEach(function(asset) {
                        
                        // DFP ad 
                        if (advertising && adInt == o.ad_dfp_interval && cardWidth >= o.ad_dfp_sizes.minColumnWidth) {
                            var adsLength = ads.length,
                                adUnitId = "ad-" + o.block_id + "-" + adsLength++;
                            
                            // Push ad unit ID to ads array for initalization
                            ads[ads.length] = {"id": adUnitId, "rendered": false};
                            
                            // Create slot
                            __tnt.ads.dfp.func.defineSlot(adUnitId, o.ad_dfp_slot, o.ad_dfp_sizes);
                            
                            // Push ad tag
                            cards.push(__tnt.ads.dfp.func.createAdTag(adUnitId));
                            adInt = 0;
                            return;
                        } else {
                            adInt++;
                        }
                        
                        var clone = __tnt.template( d.querySelector(o.template) ),
                            card = clone.querySelector(".card"),
                            headline = clone.querySelector(".tnt-headline"),
                            headlineLink = clone.querySelector(".tnt-headline a");
                        
                        // Card cleanup
                        card.id = card.id.replace(/{{uuid}}/g, asset.uuid);
                        
                        card.classList.replace('tnt-asset-type-{{type}}', 'tnt-asset-type-' + asset.type);
                        
                        card.querySelectorAll('[aria-label="{{title}}"]').forEach(function(item) {
                            item.setAttribute('aria-label', asset.title);
                        });
                        
                        // Title
                        if (o.show_premium != 'none') {
                            if (asset.is_premium) {
                                var title = headline.querySelector(".tnt-headline a");
                                title.childNodes[2] != undefined ? title.childNodes[2].textContent = asset.title : title.textContent = asset.title;
                            } else {
                                headlineLink.textContent = asset.title;
                            }
                        } else {
                            headlineLink.textContent = asset.title;
                        }
                        
                        // Open links in a new window
                        if (asset.new_window) {
                            headline.setAttribute('target','_blank');
                            headline.setAttribute('rel','noopener');
                        }
                        
                        // Byline
                        if (o.show_byline) {
                            if (asset.byline) {
                                clone.querySelector(".tnt-byline").innerHTML = asset.byline;
                                if (asset.authors.length > 0) {
                                    clone.querySelector(".tnt-byline").id = 'author-' + asset.authors[0].uuid + '-asset-' + asset.uuid;
                                } else {
                                    clone.querySelector(".tnt-byline").removeAttribute('id');
                                }
                            } else {
                                var emptyByline = clone.querySelector(".card-byline");
                                emptyByline.parentNode.removeChild(emptyByline);
                            }
                        }
                        // Author
                        if (o.show_author){
                            var authorCard = clone.querySelector(".card.author");
                            if (asset.authors.length > 0) {
                                var author = asset.authors[0];
                                var title = clone.querySelector(".tnt-user-title");
                                authorCard.classList.remove("card-author-{{uuid}}");
                                authorCard.classList.add("card-author-" + author.uuid);
                                // Author name
                                var fullName = author.full_name;
                                if (fullName == null) fullName = author.screen_name;
                                clone.querySelector(".tnt-user-name").innerHTML = fullName;
                                // Profile link
                                clone.querySelectorAll(".tnt-user-profile-link").forEach(function(link) {
                                    link.href = author.profile;
                                });
                                // Avatar 
                                if (author.avatar !== null) {
                                    var avatar = clone.querySelector(".tnt-user-avatar");
                                    avatar.removeAttribute("data-src");
                                    avatar.src = author.avatar;
                                    avatar.alt = author.screen_name;
                                } else {
                                    // Remove author avatar 
                                    var avatarBox = clone.querySelector(".avatar-box");
                                    avatarBox.parentNode.removeChild(avatarBox);
                                }
                                // Title
                                if (author.title) {
                                    title.innerHTML = author.title;
                                } else {
                                    title.parentNode.removeChild(title);
                                }
                            } else if (authorCard) {
                                // Remove author card
                                authorCard.parentNode.removeChild(authorCard);
                            }
                        }
                        
                        // Date
                        if (o.show_date) {
                            var date = clone.querySelector(".tnt-date");
                            var currentDate = new Date();
                            var seconds =  parseInt(Math.floor( Date.now() /1000 ));
                            var time = "";
                            // Updated vs start
                            if ( 
                                 seconds - (30*86400) <  asset.starttime.utc
                                 && seconds - (7*86400) <  asset.last_updated
                                 && seconds > asset.last_updated
                            ){
                                time = asset.pretty_date;
                                date.innerHTML = "Updated " + time
                            } else {
                                time = asset.starttime;
                                date.innerHTML = time.formatted;
                            }
                            date.setAttribute("datetime", time.iso8601);
                        }
                        
                        // Kicker
                        if (o.show_kicker) {
                            var kicker = clone.querySelector(".tnt-kicker");
                            if (asset.kicker){
                                kicker.innerHTML = asset.kicker;
                            } else kicker.parentNode.removeChild(kicker);
                        }
                        
                        // Hammer
                        if (o.show_hammer) {
                            var hammer = clone.querySelector(".tnt-hammer span");
                            if (asset.hammer){
                                hammer.innerHTML = asset.hammer;
                            } else hammer.parentNode.removeChild(hammer);
                        }
                        
                        // Time to consume
                        if (o.time_to_read !== "disabled") {
                            var consume = clone.querySelector(".read-time");
                            if (consume && asset.time_to_consume && asset.type == "article"){
                                consume.innerHTML = asset.time_to_consume;
                            }
                        } else {
                            var consume = clone.querySelector(".card-time-to-read");
                            if (consume) {
                                consume.parentNode.removeChild(consume);
                            }
                        }
                        
                        // Summary/lead
                        if (o.show_lead) {
                            var lead = clone.querySelector(".tnt-summary");
                            if (asset.summary) {
                                lead.innerHTML = asset.summary;
                            } else {
                                var emptyLead = clone.querySelector(".card-lead");
                                emptyLead.parentNode.removeChild(emptyLead);
                            }
                        }
                        
                        // Section
                        if (o.show_section) {
                            var section = clone.querySelector(".tnt-section-tag"),
                                tagContainer = clone.querySelector(".card-label-section");
                            
                            section.parentNode.removeChild(section);
                            
                            if (asset.sections[0]) {
                                if (!o.section_count) o.section_count = 1;
                                
                                if (o.section_count != 1) {
                                    var divider = document.createElement("span");
                                    divider.classList.add('tnt-divider');
                                    
                                    // Multiple section tags
                                    for (var i = 0; i < asset.sections.length; i++) {
                                        // Limit to specified count
                                        if (i >= o.section_count) break;
                                        
                                        // Append divider
                                        if (i > 0) tagContainer.appendChild(divider.cloneNode());
                                        
                                        if (asset.sections[i].path) {
                                            // Create linked tag
                                            var tag = section.cloneNode();
                                            tag.href = __tnt.scrubUrl(asset.sections[i].path);
                                            tag.textContent = asset.sections[i].tag;
                                            
                                            // Append linked tag to tagContainer 
                                            tagContainer.appendChild(tag);
                                        } else {
                                            // Create unlinked tag
                                            var tag = document.createElement("span");
                                            tag.classList.add('tnt-section-tag');
                                            tag.textContent = asset.sections[i].tag;
                                            
                                            // Append unlinked tag to tagContainer
                                            tagContainer.appendChild(tag);
                                        }
                                    }
                                } else {
                                    // Set single section tag
                                    section.href = __tnt.scrubUrl(asset.sections[0].path);
                                    section.textContent = asset.sections[0].tag;
                                    tagContainer.appendChild(section);
                                }
                            } else {
                                // No section tags
                                var noSection = true;
                                var emptySection = clone.querySelector(".card-label-section");
                                emptySection.parentNode.removeChild(emptySection);
                            }
                        }
                        
                        // Flags
                        if (o.show_flags) {
                            var flag_set = clone.querySelector(".card-label-flags"),
                                flag = clone.querySelector(".tnt-flag");
                            if (asset.flags != null){
                                for (var i = 0; i < asset.flags.length; i++) {
                                    var outFlag = flag.cloneNode();
                                    outFlag.innerHTML = asset.flags[i].replace(/_/g, " ");
                                    outFlag.classList.remove("label-flag-flag");
                                    outFlag.classList.add("label-flag-" + asset.flags[i].replace(/_/g, "-"));
                                    flag_set.appendChild(outFlag);
                                    flag_set.appendChild(document.createTextNode(" "));
                                }
                                flag.parentNode.removeChild(flag);
                            } else {
                                var noFlags = true;
                                flag_set.parentNode.removeChild(flag_set);
                            }
                            // If no section or flags then remove area
                            if (noSection && noFlags) {
                                var emptyLabels = clone.querySelector(".card-labels");
                                emptyLabels.parentNode.removeChild(emptyLabels);
                            }
                        }
                        
                        // Image
                        if (o.show_image) {
                            var preview = clone.querySelector(".media-preview"),
                                thumb = clone.querySelector(".media-preview img"),
                                figure = clone.querySelector(".media-preview figure");
                            if (asset.preview.srcset && !(!!window.MSInputMethodContext && !!document.documentMode)) {
                                preview.classList.remove("media-preview-{{uuid}}","{{type}}-asset");
                                preview.classList.add(asset.type + "-asset","media-preview-" + asset.uuid);
                                figure.classList.remove("layout-{{orientation}}");
                                figure.classList.add("layout-"+asset.preview.orientation);
                                thumb.srcset = asset.preview.srcset;
                                thumb.alt = asset.title;
                            } else if (asset.preview.url) {
                                preview.classList.remove("media-preview-{{uuid}}","{{type}}-asset");
                                preview.classList.add(asset.type + "-asset","media-preview-" + asset.uuid);
                                figure.classList.remove("layout-{{orientation}}");
                                figure.classList.add("layout-"+asset.preview.orientation);
                                thumb.src = asset.preview.url;
                                thumb.alt = asset.title;
                            } else {
                                // Remove image from template
                                card.classList.remove("has-image");
                                var emptyImage = clone.querySelector(".card-image");
                                emptyImage.parentNode.removeChild(emptyImage);
                            }
                            
                            // Image blur
                            if (asset.preview.blur_src && (asset.preview.srcset || asset.preview.url)) {
                                try {
                                    // Populate blurred image
                                    clone.querySelector(".tnt-blurred-image img").src = asset.preview.blur_src;
                                } catch(e){ /* No image blur */ }
                            }
                        }
                        
                        // Comment count
                        if (o.show_comment_count) {
                            var count = clone.querySelector(".card-comment-count .count");
                            
                            try {
                                clone.querySelector(".card-comment-count a.cm").href = __tnt.scrubUrl(asset.url) + '#comments';
                                var commentText = ' comments';
                                if (asset.comment_count === 1) commentText = ' comment';
                                clone.querySelector(".card-comment-count a.cm").title = asset.comment_count + commentText;
                            } catch(e){ /* Comments not enabled */ }
                            
                            if (count && asset.comment_count > 0) {
                                count.innerHTML = asset.comment_count;
                            } else if (count){
                                count.parentNode.removeChild(count);
                            }
                        }
                        
                        // Social share
                        clone.querySelectorAll(".tnt-share-link").forEach(function(share) {
                            share.href = share.href.replace(/%7B%7Burl%7D%7D/g, asset.url).replace(/%7B%7Btitle%7D%7D/g, encodeURI(asset.title));
                            if (share.dataset.tncmsTrackEvent){
                               share.dataset.tncmsTrackEvent = share.dataset.tncmsTrackEvent.replace(/{{uuid}}/g, asset.uuid).replace(/"app":""/g, '"app":"' + asset.app + '"');
                            }
                            if (share.dataset.track){
                                share.dataset.track = share.dataset.track.replace(/-{{type}}/g, asset.app + '-' + asset.type);
                            }
                        });
                        
                        // Asset links
                        clone.querySelectorAll(".tnt-asset-link").forEach(function(link) {
                            link.href = asset.url;
                        });
                        
                        // Asset icons
                        var icon = clone.querySelector(".asset-icon");
                        if ((icon && icon.innerHTML)!= null) {
                            (asset.icon != "") ? icon.innerHTML = asset.icon : icon.parentNode.removeChild(icon);
                        }
                        
                        // Push completed card
                        cards.push(document.importNode(clone, true));
                    });
                }
                
                function hideTrigger() {
                    if (o.fetch_method == "select") {
                        if (o.fetch_method_alt != 'disabled') {
                            // Hide supplemental discovery trigger
                            alt_trigger.classList.add('hidden');
                        }
                        // Do not hide select trigger
                        return;
                    }
                    target.classList.add('hidden');
                }
                
                function updateSelect(input) {
                    // Update option grouping to match choices
                    o.sections.forEach(function(option, i) {
                        // Compare base set to selected
                        if (input.includes( option.value )){
                            // Move choice to followed
                            control.updateOption(option.value, {
                                class: 'followed', 
                                value: option.value, 
                                text: option.text
                            })
                        } else {
                            // Move choice to all
                            control.updateOption(option.value, {
                                class: 'all', 
                                value: option.value, 
                                text: option.text
                            })
                        }
                    });
                    // Refresh option display
                    control.refreshOptions();
                    control.close();
                }
            })
        }
    };
    if (d.readyState == "loading") {
        d.addEventListener('DOMContentLoaded', function() {
            discovery.init();
        })
    } else {
        discovery.init();
    }
})(window, document);